export const BaseColors = {
  orange: '#ff7700',
  lightOrange: '#f5a967',
  white: '#fff',
  black: '#000',
}

export const ElementColors = {
  primaryText: BaseColors.orange,
  primaryTextHover: BaseColors.lightOrange,
  secondaryText: BaseColors.black,
  primaryBackground: BaseColors.black,
  primaryBackgroundHover: BaseColors.orange,
  secondaryBackground: BaseColors.orange,
}
