import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { ElementColors } from '../styles/styleVariables'

const Wrapper = styled.footer`
  background: ${ElementColors.primaryBackground};
  color: ${ElementColors.primaryText};
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
`

const InnerWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    text-decoration: none;
    color: ${ElementColors.primaryText};
    &:hover {
      color: ${ElementColors.primaryTextHover};
    }
  }
`

const ListHeader = styled.div`
  margin-bottom: 12px;
  font-size: 22px;
`

const FooterText = styled.div`
  padding: 25px;
  max-width: 600px;
`

const Footer = () => (
  <Wrapper>
    <InnerWrapper>
      <FooterText>
        <ListHeader>Om ANTak</ListHeader>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Mauris in aliquam
        sem fringilla ut morbi tincidunt augue.
      </FooterText>
    </InnerWrapper>
    <InnerWrapper>
      <List>
        <ListHeader>Kontakt:</ListHeader>
        <Item>Foos väg 4</Item>
        <Item>12345</Item>
        <Item>Bar</Item>
        <Item>
          <a href="tel:0701234567">Tel: 070 - 123 45 67</a>
        </Item>
        <Item>
          <a href="mailto:abc@de.fg">Mail: abc@de.fg</a>
        </Item>
      </List>
      <List>
        <ListHeader>Meny:</ListHeader>
        <Item>
          <Link to="/ANTak">Om</Link>
        </Item>
        <Item>
          <Link to="/projekt">Projekt</Link>
        </Item>
        <Item>
          <Link to="/kontakt">Kontakt</Link>
        </Item>
      </List>
    </InnerWrapper>
  </Wrapper>
)

export default Footer
