import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import { BaseColors, ElementColors } from '../styles/styleVariables'

const headerHeight = '70px'

const Header = styled.header`
  align-items: center;
  background: ${props => props.theme.colors.primary};
  width: 100%;
  height: ${headerHeight};
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  height: ${headerHeight};
  margin: 0 auto;
  padding: 0 1.5em;
`

const Logo = styled.div`
  position: relative;
  flex-basis: 65px;
  height: ${headerHeight};
  padding-left: 5px;
  background: ${BaseColors.orange};
  line-height: ${headerHeight};

  &:after {
    content: '';
    background: ${BaseColors.orange};
    position: absolute;
    top: 0;
    right: -17px;
    width: 25px;
    height: ${headerHeight};
    transform: skewX(-13deg);
  }

  span {
    color: white;
  }

  a {
    color: black;
    text-decoration: none;
  }
`

const MenuList = styled.ul`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`

const MenuListItem = styled.li`
  display: inline-block;
  margin-left: 1em;

  a {
    text-decoration: none;
    color: ${ElementColors.primaryText};
    font-weight: 600;
    transition: all 0.2s;
    border-bottom: 2px solid ${props => props.theme.colors.text};

    &:hover {
      color: ${ElementColors.primaryTextHover};
    }
  }
`

const activeLinkStyle = {
  color: 'white',
}

const Menu = () => {
  const { menuLinks } = useSiteMetadata()
  return (
    <Header>
      <Nav>
        <Logo>
          <Link to="/">
            <span>AN</span>TAK
          </Link>
        </Logo>
        <MenuList>
          {menuLinks.map(link => (
            <MenuListItem key={link.name}>
              <Link to={link.slug} activeStyle={activeLinkStyle}>
                {link.name}
              </Link>
            </MenuListItem>
          ))}
        </MenuList>
      </Nav>
    </Header>
  )
}

export default Menu
